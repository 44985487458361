<template>
  <button class="quote_preview_button_exporter" @click="generateExcel">
    {{ text }}
  </button>

  <div style="display: none !important">
    <table
      class="budget_strategie"
      v-for="(strategy, index) in this.quote.strategies"
      :key="index"
    >
      <body>
        <tr gruppo="table_recap_space">
          <td>{{ strategy.name }}</td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
      </body>
      <body v-for="(goal, goalIndex) in strategy.goals" :key="goalIndex">
        <tr gruppo="table_recap_border" class="table_recap_border">
          <td gruppo="table_recap_border" class="table_recap_border">Budget</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.budget) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr gruppo="table_recap_border" class="table_recap_border">
          <td gruppo="table_recap_border" class="table_recap_border">Numero di post</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ goal.posts }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr gruppo="table_recap_border" class="table_recap_border">
          <td gruppo="table_recap_border" class="table_recap_border">
            *di cui numero post massimo del Network Premium
          </td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ goal.posts_premium }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr gruppo="table_recap_border" class="table_recap_border">
          <td gruppo="table_recap_border" class="table_recap_border">Timing</td>
          <td gruppo="table_recap_border" class="table_recap_border"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <!-- video reach -->
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="strategy.type === 'video_reach'"
        >
          <td gruppo="table_recap_border" class="table_recap_border">Reach</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.reach) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="strategy.type === 'video_reach'"
        >
          <td gruppo="table_recap_border" class="table_recap_border">Impressions</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.impressions) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <!-- fine video reach -->
        <!-- video view 3s -->
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="strategy.type === 'video_view' && strategy.preferred_views === 'views_3s'"
        >
          <td gruppo="table_recap_border" class="table_recap_border">Views 3"</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.views_3s) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="strategy.type === 'video_view' && strategy.preferred_views === 'views_3s'"
        >
          <td gruppo="table_recap_border" class="table_recap_border">Impressions</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.impressions_min) }} -
            {{ formatNumber(goal.impressions_max) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <!-- fine video view 3s -->
        <!-- video view 15s -->
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="
            strategy.type === 'video_view' && strategy.preferred_views === 'views_15s'
          "
        >
          <td gruppo="table_recap_border" class="table_recap_border">Views 15"</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.views_15s) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="
            strategy.type === 'video_view' && strategy.preferred_views === 'views_15s'
          "
        >
          <td gruppo="table_recap_border" class="table_recap_border">Impressions</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.impressions_min) }} -
            {{ formatNumber(goal.impressions_max) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <!-- fine video view 15s -->
        <!-- foto -->
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="strategy.type === 'image'"
        >
          <td gruppo="table_recap_border" class="table_recap_border">Reach</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.reach) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr
          gruppo="table_recap_border"
          class="table_recap_border"
          v-if="strategy.type === 'image'"
        >
          <td gruppo="table_recap_border" class="table_recap_border">Impressions</td>
          <td gruppo="table_recap_border_center" class="table_recap_border">
            {{ formatNumber(goal.impressions) }}
          </td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <!-- fine foto -->
        <tr>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
        <tr>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
      </body>
      <!-- spazio sotto -->
      <body>
        <tr>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
          <td gruppo="table_recap_space"></td>
        </tr>
      </body>
      <!-- fine spazio sotto -->
    </table>

    <table id="editori_premium">
      <body>
        <!-- titolone -->
        <tr gruppo="sottotitolo_tabella_tag_tr" class="table_recap_border">
          <td gruppo="sottotitolo_tabella_tag_prima"></td>
          <td gruppo="sottotitolo_tabella_tag_seconda"></td>
          <td gruppo="sottotitolo_tabella_tag_terza">EDITORI CONSIGLIATI</td>
          <td gruppo="sottotitolo_tabella_tag_quarta"></td>
          <td gruppo="sottotitolo_tabella_tag_quinta"></td>
        </tr>
        <!-- fine titolone -->
        <!-- titoli -->
        <tr gruppo="sottotitolo_tabella_tag_tr" class="table_recap_border">
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            NOME PAGINA
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            FACEBOOK
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            FAN
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            INSTAGRAM
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            FAN
          </td>
        </tr>
        <!-- fine titoli -->
      </body> 
      <!-- tags ed editori -->
      <body v-for="(tag, tagIndex) in this.quote.tags" :key="tagIndex">             
        <!-- valori -->
        <tr
          gruppo="valore_tabella_tag_tr"
          class="table_recap_border"
          v-for="(editor, editorIndex) in tag.editors"
          :key="editorIndex"
        >
          <td
            v-show="editor.preferred"
            :gruppo="gruppoNomeEditore(editor.discriminators)"          
            class="table_recap_border"
          >
            {{ editor.editor.name }}
          </td>
          <td
            v-show="editor.preferred"
            :gruppo="gruppoNomeValoreF(editor.discriminators)" 
            class="table_recap_border"
          >
            <a :href="editor.editor.link" target="_blank">{{ editor.editor.link }}</a>
          </td>
          <td
            v-show="editor.preferred"
            :gruppo="gruppoNomeValoreF(editor.discriminators)"  
            class="table_recap_border"
          >
            {{ formatNumber(editor.editor.follower) }}
          </td>
          <td
            v-show="editor.preferred"
            :gruppo="gruppoNomeValoreI(editor.discriminators)" 
            class="table_recap_border"
          >
            <a
              v-if="editor.editor.instagram_Link"
              :href="editor.editor.instagram_Link"
              target="_blank"
              >{{ editor.editor.instagram_Link }}</a
            >
            <span v-else>X</span>
          </td>
          <td
            v-show="editor.preferred"
            :gruppo="gruppoNomeValoreI(editor.discriminators)" 
            class="table_recap_border"
          >
            {{ formatNumber(editor.editor.instagram_Fanbase) }}
          </td>
        </tr>
        <!-- fine valori -->       
      </body>
      <body>
        <!-- spazio sotto -->
        <tr gruppo="spazio_tabella_tag_tr">
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <tr gruppo="spazio_tabella_tag_tr">
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <tr gruppo="spazio_tabella_tag_tr">
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <!-- fine spazio sotto -->
      </body>
      <!-- tags ed editori -->
    </table>

    <table id="editori_tags">
      <!-- tags ed editori -->
      <body v-for="(tag, tagIndex) in this.quote.tags" :key="tagIndex">
        <tr gruppo="titolo_tabella_tag_tr" class="table_recap_border">
          <td gruppo="titolo_tabella_tag_td" class="table_recap_border">
            {{ formatUppercaseString(tag.tag.name) }}
          </td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <tr gruppo="sottotitolo_tabella_tag_tr" class="table_recap_border">
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            NOME PAGINA
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            FACEBOOK
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            FAN
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            INSTAGRAM
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_centrato" class="table_recap_border">
            FAN
          </td>
        </tr>
        <tr
          gruppo="valore_tabella_tag_tr"
          class="table_recap_border"
          v-for="(editor, editorIndex) in tag.editors"
          :key="editorIndex"
        >
          <td gruppo="valore_tabella_tag_td" class="table_recap_border">
            {{ editor.editor.name }}
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_c_valore" class="table_recap_border">
            <a :href="editor.editor.link" target="_blank">{{ editor.editor.link }}</a>
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_c_valore" class="table_recap_border">
            {{ formatNumber(editor.editor.follower) }}
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_c_valore" class="table_recap_border">
            <a
              v-if="editor.editor.instagram_Link"
              :href="editor.editor.instagram_Link"
              target="_blank"
              >{{ editor.editor.instagram_Link }}</a
            >
            <span v-else>X</span>
          </td>
          <td gruppo="sottotitolo_tabella_tag_td_c_valore" class="table_recap_border">
            {{ formatNumber(editor.editor.instagram_Fanbase) }}
          </td>
        </tr>
        <!-- spazio sotto -->
        <tr gruppo="spazio_tabella_tag_tr">
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <tr gruppo="spazio_tabella_tag_tr">
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <tr gruppo="spazio_tabella_tag_tr">
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
          <td gruppo="spazio_tabella_tag_td"></td>
        </tr>
        <!-- fine spazio sotto -->
      </body>
      <!-- tags ed editori -->
    </table>
  </div>
</template>

<script>
import ExcelJS from "exceljs";
//import { saveAs } from "file-saver";

export default {
  props: {
    quote: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      default: "Download Excel",
    },
  },
  methods: {
    gruppoNomeEditore(discriminators) {
      if (discriminators.length !== 0) {
        return 'valore_tabella_tag_td_sott'; 
      } else if (discriminators.length === 0) {
        return 'valore_tabella_tag_td';
      }
      return 'valore_tabella_tag_td';
    },
    gruppoNomeValoreF(discriminators) {
      if (discriminators.includes('Facebook') && !discriminators.includes('Instagram')) {
        return 'sottotitolo_tabella_tag_td_c_val_sott_fb';
      } else if (discriminators.includes('Instagram') && discriminators.includes('Facebook')) {
        return 'sottotitolo_tabella_tag_td_c_val_sott';  
      } else if (discriminators.length === 0) {
        return 'sottotitolo_tabella_tag_td_c_valore';
      }
      return 'sottotitolo_tabella_tag_td_c_valore';
    },
    gruppoNomeValoreI(discriminators) {
      if (discriminators.includes('Instagram') && !discriminators.includes('Facebook')) {
        return 'sottotitolo_tabella_tag_td_c_val_sott_ig';
      } else if (discriminators.includes('Instagram') && discriminators.includes('Facebook')) {
        return 'sottotitolo_tabella_tag_td_c_val_sott';  
      } else if (discriminators.length === 0) {
        return 'sottotitolo_tabella_tag_td_c_valore';
      }
      return 'sottotitolo_tabella_tag_td_c_valore';
    },
    formatLink(link, name) {
      const lc_name = name.toLowerCase().replace(/\s+/g, "");
      const sanitizedLink = link.replace(/^https?:\/\//, ""); // Rimuove http:// o https://
      const linkParts = sanitizedLink.split("/");
      linkParts[linkParts.length - 1] = lc_name;
      return linkParts.join("/");
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatUppercaseString(name) {
      return name.toString().toUpperCase();
    },
    async generateExcel() {
      const workbook = new ExcelJS.Workbook();
      workbook.creator = "Inflooendo";
      workbook.lastModifiedBy = "Inflooendo";
      const worksheet = workbook.addWorksheet("Quote Report");

      // budget e strategie
      const tables = document.querySelectorAll(".budget_strategie");
      tables.forEach((table) => {
        const rows = table.querySelectorAll("tr");
        rows.forEach((row) => {
          const cells = row.querySelectorAll("td, th");
          const rowData = [];
          cells.forEach((cell) => {
            const cellData = cell.innerText;
            rowData.push(cellData);
          });
          const excelRow = worksheet.addRow(rowData);

          // budgets e strategie
          excelRow.eachCell((cell, colNumber) => {
            const cellElement = row.querySelector(`td:nth-child(${colNumber})`);
            const customClass = cellElement.getAttribute("gruppo");

            if (customClass === "table_recap_border") {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            } else if (customClass === "table_recap_border_center") {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cell.alignment = { horizontal: "center" };
            }
          });
        });
      });

      // editori consigliati
      var table = document.getElementById("editori_premium");
      var rows = table.querySelectorAll("tr");
      rows.forEach((row) => {
        const cells = row.querySelectorAll("td, th");
        const rowData = [];
        cells.forEach((cell) => {
          let cellData = cell.innerText;

          // rendo i link cliccabili
          const link = cell.querySelector("a");
          if (link) {
            cellData = {
              text: link.innerText,
              hyperlink: link.href
            };
          }

          rowData.push(cellData);
        });
        const excelRow = worksheet.addRow(rowData);

        // titolo tabella tags
        excelRow.eachCell((cell, colNumber) => {
          const cellElement = row.querySelector(`td:nth-child(${colNumber})`);
          const customClass = cellElement.getAttribute("gruppo");
          //console.log("classe: " + customClass);

          if (customClass === "titolo_tabella_tag_td") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
          } else if (customClass === "sottotitolo_tabella_tag_td") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF47b2e1" },
            };
          } else if (customClass === "valore_tabella_tag_td") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          } else if (customClass === "valore_tabella_tag_td_sott") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };  
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFd5e8cd" },
            };
          } else if (customClass === "sottotitolo_tabella_tag_td_centrato") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF47b2e1" },
            };
            cell.alignment = { horizontal: "center" };
          } else if (customClass === "sottotitolo_tabella_tag_td_c_valore") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.alignment = { horizontal: "center" };
          } else if (customClass === "sottotitolo_tabella_tag_td_c_val_sott") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFd5e8cd" },
            };
            cell.alignment = { horizontal: "center" };   
          } else if (customClass === "sottotitolo_tabella_tag_td_c_val_sott_fb") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFd5e8cd" },
            };
            cell.alignment = { horizontal: "center" };  
          } else if (customClass === "sottotitolo_tabella_tag_td_c_val_sott_ig") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFd5e8cd" },
            };
            cell.alignment = { horizontal: "center" };  
            // prima riga
          } else if (customClass === "sottotitolo_tabella_tag_prima") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
            // seconda riga
          } else if (customClass === "sottotitolo_tabella_tag_seconda") {
            cell.border = {
              top: { style: "thin" },
              bottom: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
            // terza riga
          } else if (customClass === "sottotitolo_tabella_tag_terza") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              bottom: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
            cell.alignment = { horizontal: "center" };
            // quarta riga
          } else if (customClass === "sottotitolo_tabella_tag_quarta") {
            cell.border = {
              top: { style: "thin" },
              bottom: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
            // quita riga
          } else if (customClass === "sottotitolo_tabella_tag_quinta") {
            cell.border = {
              top: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
          }
        });       
      });

      // tags ed editori
      table = document.getElementById("editori_tags");
      rows = table.querySelectorAll("tr");
      rows.forEach((row) => {
        const cells = row.querySelectorAll("td, th");
        const rowData = [];
        cells.forEach((cell) => {
          let cellData = cell.innerText;

          // rendo i link cliccabili
          const link = cell.querySelector("a");
          if (link) {
            cellData = {
              text: link.innerText,
              hyperlink: link.href
            };
          }

          rowData.push(cellData);
        });
        const excelRow = worksheet.addRow(rowData);

        // titolo tabella tags
        excelRow.eachCell((cell, colNumber) => {
          const cellElement = row.querySelector(`td:nth-child(${colNumber})`);
          const customClass = cellElement.getAttribute("gruppo");
          //console.log("classe: " + customClass);

          if (customClass === "titolo_tabella_tag_td") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF8abed5" },
            };
          } else if (customClass === "sottotitolo_tabella_tag_td") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF47b2e1" },
            };
          } else if (customClass === "valore_tabella_tag_td") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          } else if (customClass === "sottotitolo_tabella_tag_td_centrato") {
            cell.font = { bold: true };
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF47b2e1" },
            };
            cell.alignment = { horizontal: "center" };
          } else if (customClass === "sottotitolo_tabella_tag_td_c_valore") {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
            cell.alignment = { horizontal: "center" };
          }
        });
      });

      worksheet.columns = [
        { width: 50 },
        { width: 50 },
        { width: 20 },
        { width: 50 },
        { width: 20 },
      ];

      // Genera il file Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Salva il file
      // Se usassi file-saver:
      //saveAs(blob, `${this.quote.cod}_${this.quote.campaign.name}.xlsx`);
      // Altrimenti (api native browser):
      // Crea un link di download e simulare un clic su di esso
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.quote.cod}_${this.quote.campaign.name}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
  },
};
</script>

<style scoped>
.table_recap_border {
  border: 1px solid black;
}
.quote_preview_button_exporter {
  font-size: 18px !important;
  height: 37px !important;
  cursor: pointer !important;
  font-family: "Bebas Neue", cursive !important;
  color: black !important;
  font-weight: normal !important;
  line-height: 18px !important;
  text-align: center !important;
  background-color: #2cbeff !important;
  border: none !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 18px !important;
  padding-left: 18px !important;
  border: 1px solid white !important;
}
.quote_preview_button_exporter:hover {
  color: white !important;
}
</style>
